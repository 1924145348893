import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store.js';
import { signOut } from "firebase/auth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      const user = store.getters.getUser;
      if (user) {
        next();
      } else {
        next({name: "/about"});
      }
    },
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/farmers',
    name: 'Farmers',
    beforeEnter: (to, from, next) => {
      const user = store.getters.getUser;
      if (user) {
        next();
      } else {
        next({name: "/about"});
      }
    },
    component: () => import('../views/Farmers.vue'),
  },
  {
    path: '/manual',
    name: 'Manual',
    beforeEnter: (to, from, next) => {
      const user = store.getters.getUser;
      if (user) {
        next();
      } else {
        next({name: "/about"});
      }
    },
    component: () => import('../views/Manual.vue'),
  },
  {
    path: '/auto',
    name: 'Auto',
    beforeEnter: (to, from, next) => {
      const user = store.getters.getUser;
      if (user) {
        next();
      } else {
        next({name: "/about"});
      }
    },
    component: () => import('../views/Auto.vue'),
  },


  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    beforeEnter: async (to, from, next) => {
      const user = store.getters.getUser;
      if (!user) {
        next();
      } else {
        next(false);
      }
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from, next) => {
    const auth = Vue.firebase.auth;
      await signOut(auth)
         next({name: "About"});
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
