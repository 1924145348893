import Vue from 'vue'
import VueGoogleCharts from 'vue-google-charts'
import App from './App.vue'
import store from '@/store.js';
import router from './router'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore"
import { getFunctions, httpsCallable } from "firebase/functions";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const firebaseConfig = {
  apiKey: "AIzaSyCG-hCCuDhFUYiU9A0uVz6Vvd948grTPEU",
  authDomain: "leekwarsv2.firebaseapp.com",
  projectId: "leekwarsv2",
  storageBucket: "leekwarsv2.appspot.com",
  messagingSenderId: "443760346698",
  appId: "1:443760346698:web:6270528340a96ceba2c8b3",
  measurementId: "G-2E8YNE810Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = initializeFirestore(app, {cacheSizeBytes: CACHE_SIZE_UNLIMITED});
const functions = getFunctions(app, 'europe-west3');
/*
enableIndexedDbPersistence(db)
  .catch((err) => {
      if (err.code == 'failed-precondition') {
        console.log("Multiple ")
      } else if (err.code == 'unimplemented') {
        console.log("Unimplemented Cache")
      }
  });
*/

//import { connectFirestoreEmulator } from "firebase/firestore"
//import { connectFunctionsEmulator } from "firebase/functions";
//connectFirestoreEmulator(db, 'localhost', 8080);
//connectFunctionsEmulator(functions, "localhost", 5001);

Sentry.init({
  Vue,
  dsn: "https://e4f23ced2ff34bdd977602e523a83cc9@o1072172.ingest.sentry.io/6168075",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "lw.cl-dev.ovh", "leekwarsv2.web.app", "leekwarsv2.firebaseapp.com",/^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('setUser', user);
  } else {
    store.dispatch('setUser', null);
  }
});

Vue.config.productionTip = false

Vue.firebase = Object;
Vue.firebase.app = app;
Vue.firebase.analytics = analytics;
Vue.firebase.auth = auth;
Vue.firebase.db = db;

Vue.firebase.functions = Object;
Vue.firebase.functions.base = functions;
Vue.firebase.functions.getFullFarmers = httpsCallable(functions, 'getFullFarmers');
Vue.firebase.functions.buyInMarket = httpsCallable(functions, 'buyInMarket');

Vue.use(VueGoogleCharts)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
