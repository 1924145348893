import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
	plugins: [createPersistedState()],
	state: {
		user: null
	},
	getters:{
		getUser: state => {
			return state.user;
		}
	},
	mutations: {
		setUser(state, user){
			console.log("User setted to " + user);
			state.user = user;
		}
	},
	actions: {
		setUser(context, user){
			context.commit('setUser', user);
		},
	}
})